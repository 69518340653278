import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import "./Superadmin.css";
import Modal from "./components/Common/Modal";
import TableData from "./components/Table/Table";
import { Avatar, Button } from "@mui/material";
import XeroIcon from '../../assest/icon-xero.png';
import { fetchApi } from "../../services/api.service";

const Superadmin = ({ user }) => {
  const [open, setOpen] = useState(false);
  const [dialogData, setDialogData] = useState();
  const [getAllUser, setGetAllUser] = useState(null);

  const handleClick = (data) => {
    setDialogData(data);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // connect to xero
  const handleConnectToXero = async () => {
    let response = await fetchApi(`/api/xero/connect`);
    response = await response.json();
    window.open(response.consentUrl, "_blank");
  };

  return (
    <div className="fw-100">
      {user.role == 1 && <Navbar user={user} />}
      <div className={user.role == 1 ? "super-container equal-space" : 'super-container  p-10'}>
        <div className="buttons">
          <button
            className="btn-primary pointer"
            onClick={() => handleClick("employee")}
          >
            Add New User
          </button>
          <Button onClick={handleConnectToXero} variant="outlined" endIcon={<Avatar src={XeroIcon} sx={{ width: 24, height: 24 }} />}>
            Connect to Xero
          </Button>
          {/* <button className='btn-primary pointer' onClick={() => handleClick('organisation')}>Add New Organisation</button> */}
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          data={dialogData}
          getAllUser={getAllUser}
        />
        <div className="my-20">
          <h3>User List</h3>
          <TableData setGetAllUser={setGetAllUser}></TableData>
        </div>
      </div>
    </div>
  );
};

export default Superadmin;
