import React, { useEffect, useState } from "react";
import './SenttoXero.css'
import { getBill, getSplitbillData } from "../../services/Currency.service";
import Spinner from "../../components/Spinner/Spinner";
import { Box } from "@mui/system";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Card } from "@mui/material";



export const SenttoXero = () => {
  const [splitbill, setSplitbill] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [bills, setBills] = useState([])

  const fetchsplitBill = async () => {
    setIsLoading(true);
    let bills = await getSplitbillData();
    setSplitbill(bills);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchsplitBill();
  }, []);

  const fetchBill = async () => {
    setIsLoading(true);
    let bills = await getBill();
    setBills(bills.palyload);
  };

  useEffect(() => {
    fetchBill();
  }, []);

  return (
    <div>
      <div>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            {splitbill.filter(element => element.bill.status === "X").length > 0 || bills.filter((elem) => elem.status === 'X').length > 0 ? (
              <>
                {splitbill
                  .filter(element => element.bill.status === "X")
                  .map((element, index) => (
                    <Box key={index} component={Paper} className="sentToXero-bill">
                      <div className="table-details-bill">
                        <div className="table-details-bill-left">
                          <div className="outflowbill-invoice">
                            <span className="pdfLogo-img">
                              <img
                                src="https://cdn.iconscout.com/icon/free/png-256/free-pdf-file-20-504245.png"
                                alt=""
                              />
                            </span>
                            <p className="outflow-invoice-number">{element.bill.invoice_no}</p>
                          </div>
                        </div>
                        <div className="table-details-bill-right">
                          <div className="field-col">
                            <div className="field">
                              <p className="tags">From : </p>
                              <p className="tags1">{element.bill.vendor.name}</p>
                            </div>
                            <div className="field">
                              <p className="tags">To :</p>
                              <p className="tags1">{element.bill.organisation.name}</p>
                            </div>
                          </div>
                          <div className="field-col">
                            <div className="field">
                              <p className="tags">PO Number :</p>
                              <p className="tags1">{element.bill.po_no}</p>
                            </div>
                            <div className="field">
                              <p className="tags">PO Amount :</p>
                              <p className="tags1">${element.bill.po_amount}</p>
                            </div>
                          </div>
                          <div className="field-col">
                            <div className="field">
                              <p className="tags">Sub-Total :</p>
                              <p className="tags1">{element.bill.currency}</p>
                              <p className="tags1">${element.bill.subtotal}</p>
                            </div>
                            <div className="field">
                              <p className="tags">GL Code :</p>
                              <p className="tags1">{element.gl_code_rec}</p>
                            </div>
                          </div>
                          <div className="field-col">
                            <div className="field">
                              <p className="tags">GST :</p>
                              <p className="tags1">{element.bill.currency}</p>
                              <p className="tags1">${element.bill.total_gst}</p>
                            </div>
                            <div className="field">
                              <p className="tags">Rule :</p>
                              <p className="tags1">{element.rule}</p>
                            </div>
                          </div>
                          <div className="field">
                            <div className="field">
                              <p className="tags">Total :</p>
                              <p className="tags1">{element.bill.currency}</p>
                              <p className="tags1">${element.bill.total_amt}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <TableContainer
                          className="center-table-div"
                          sx={{ '& .MuiTableCell-root': { fontSize: '10px', fontWeight: '600' } }}
                        >
                          <Table size="small" sx={{ margin: '0 8px' }} aria-label="a dense table">
                            <TableHead>
                              <TableRow>
                                <TableCell align="left">Invoice No</TableCell>
                                <TableCell align="left">Company</TableCell>
                                <TableCell align="left">Description</TableCell>
                                <TableCell align="left">Split%</TableCell>
                                <TableCell align="left">GST</TableCell>
                                <TableCell align="left">Total (Exclusive GST.)</TableCell>
                                <TableCell align="left">Local(NZD)</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {element.org_splits.map((orgSplit, idx) => (
                                <TableRow key={orgSplit.name}>
                                  <TableCell component="th" scope="orgSplit" align="left">
                                    {orgSplit.split_invoice_no}R
                                  </TableCell>
                                  <TableCell align="left">{orgSplit.organisation.name}</TableCell>
                                  <TableCell align="left">{orgSplit.description}</TableCell>
                                  <TableCell align="left">{orgSplit.split_per}%</TableCell>
                                  <TableCell align="left" className="td-data">
                                    {orgSplit.gst ? (
                                      <span>&#10003;</span>
                                    ) : (
                                      <>&#88;</>
                                    )}
                                    &nbsp;&nbsp;&nbsp;
                                    <span className="span-data">
                                      {orgSplit.organisation.currency}
                                      {orgSplit.gst_amount}
                                    </span>
                                  </TableCell>
                                  <TableCell align="left">
                                    {orgSplit.organisation.currency}
                                    {orgSplit.gst_amount}
                                  </TableCell>
                                  <TableCell align="left">{orgSplit.local_nzd}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                      <div className="send-to-xero-buttons">
                        <div className="send-to-xero-approved-button">
                          Sent to Xero
                        </div>
                      </div>
                    </Box>
                  ))
                }
                {
                  bills.filter((elem) => elem.status === 'XB').map((elem) => {
                    return (
                      <Card key={elem.id} sx={{ padding: "10px", margin: "10px 0" }}>
                        <div className="dir_bill_apprvd_data">
                          <div className="dir_bill_apprvd_data_div1">
                            <p className="pdfLogo-img">
                              <img
                                src="https://cdn.iconscout.com/icon/free/png-256/free-pdf-file-20-504245.png"
                                alt=""
                              />
                            </p>
                            <p className="outflow-invoice-number">{elem.invoice_no}</p>
                          </div>
                          <div className="dir_bill_apprvd_data_div">
                            <div className="dir_bill_apprvd_data_div_field">
                              <div className="field_div_data">
                                <p className="tags">From : </p>
                                <p className="tags1">{elem.vendor.name}</p>
                              </div>
                              <div className="field_div_data">
                                <p className="tags">GST :</p>
                                <p className="tags1">{elem.currency}</p>
                                <p className="tags1">${elem.total_gst}</p>
                              </div>
                            </div>
                            <div className="dir_bill_apprvd_data_div_field">
                              <div className="field_div_data">
                                <p className="tags">To :</p>
                                <p className="tags1">{elem.organisation.name}</p>
                              </div>
                              <div className="field_div_data">
                                <p className="tags">GL Code :</p>
                                <p className="tags1">{elem.gl_code || "N/A"}</p>
                              </div>
                            </div>
                            <div className="dir_bill_apprvd_data_div_field">
                              <div className="field_div_data">
                                <p className="tags">Sub-Total :</p>
                                <p className="tags1">{elem.currency}</p>
                                <p className="tags1">${elem.subtotal}</p>
                              </div>
                              <div className="field_div_data">
                                <p className="tags">PO Amount :</p>
                                <p className="tags1">${elem.po_amount || "0.00"}</p>
                              </div>
                            </div>
                            <div className="dir_bill_apprvd_data_div_field">
                              <div className="field_div_data">
                                <p className="tags">Total :</p>
                                <p className="tags1">{elem.currency}</p>
                                <p className="tags1">${elem.total_amt}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="director-approved-button">
                          {elem.status === "XB" ? "Sent to Xero" : ""}
                        </div>
                      </Card>
                    )
                  })
                }
              </>
            ) : (
              <div style={{ textAlign: "center" }}>No data</div>
            )}
          </>
        )}
      </div>
    </div>
  );
};


