import React, { useContext, useEffect, useState } from "react";
import "./OutflowSplitbill.css";
import OutflowSplitbillShare from "../OutflowSplitbillShare/OutflowSplitbillShare";
import { AddSplitbill } from "../../services/Currency.service";
import { Button } from "@mui/material";
import GlobalContext from "../TimesheetContext/GlobalContext";
import LoadingButton from "@mui/lab/LoadingButton";
import SnackbarMsg from "../../components/superadmin-home/components/Common/Snackbar";
import { glCodeList } from "../../Data/data";
import { validateTaxRates } from "../../services/api.service";

const OutflowSplitbill = ({
  invoice_id,
  amount,
  currency,
  handleSplitFlag,
  gst
}) => {
  const initialvalues = {
    rule: "",
    gl_code: "",
    org_split: [],
  };

  const { setValue } = useContext(GlobalContext);

  const [splitbill, setSplitbill] = useState(initialvalues);
  const [isSubmit, setIsSubmit] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [apiMsg, setApiMsg] = useState(null)
  const [MsgColor, setMsgColor] = useState(null)

  const amt = amount;

  const handleChange = (e) => {
    e.preventDefault();
    setSplitbill({ ...splitbill, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    if (splitbill.org_split.length == 0) {
      setOpenSnackbar(true);
      setApiMsg("Please Split Bills First");
      setMsgColor("red");
      setTimeout(() => { setOpenSnackbar(false) }, [1500])
      return;
    }
    if (splitbill.rule.length === 0 || splitbill.gl_code.length === 0) {
      setOpenSnackbar(true);
      setApiMsg("Please filed up all the mandatory fields");
      setMsgColor("red");
      setIsSubmit(false)
      setTimeout(() => { setOpenSnackbar(false) }, [1500])
      return;
    }
    /// validating fields of outflowSplitBillShare input fields
    for (const org of splitbill?.org_split) {
      if (!org.company_abbr?.length || !org.description?.length || !org.split_per?.length) {
        setOpenSnackbar(true);
        setApiMsg("Please fill up all the mandatory fields");
        setMsgColor("red");
        setIsSubmit(false);
        setTimeout(() => { setOpenSnackbar(false) }, 1500);
        return;
      }
    }

    // validating tax rate
    let taxRateArr = splitbill.org_split.map((item) => item["split_per"])

    let validationResult = validateTaxRates(taxRateArr);
    // console.log("validationResult", validationResult)

    if (validationResult.isValid === false) {
      setApiMsg(validationResult.message);
      setMsgColor("red")
      setOpenSnackbar(true);
      setTimeout(() => setOpenSnackbar(false), 1500);
      setIsSubmit(false)
      return;
    }

    // calculating the sum of the split percentage (<=100)
    let total_split_per = 0;
    for (let item of splitbill.org_split) {
      total_split_per += parseFloat(item.split_per);
    }
    // console.log(total_split_per, "total_split_per 1")
    // Check if total split percentage exceeds 100
    if (total_split_per > 100) {
      setApiMsg("some of split % can not be greater than 100");
      setMsgColor("red")
      // console.log(total_split_per, "total_split_per")
      setOpenSnackbar(true);
      setTimeout(() => setOpenSnackbar(false), 1500);
      setIsSubmit(false)
      // Reset total_split_per to 0 to avoid retaining old value
      total_split_per = 0;
      return;
    }

    setIsSubmit(true)
    const formattedData = {
      ...splitbill,
    };

    await AddSplitbill(formattedData, invoice_id).then((result) => {
      setOpenSnackbar(true);
      setIsSubmit(false)
      setApiMsg(result?.message || result?.err)
      setMsgColor(result?.success ? 'green' : 'red')
      if (result?.success) {
        setTimeout(() => { setValue(1) }, [1800])
      }
    })
      .catch((error) => {
        console.log(error);
      });

    setTimeout(() => { setOpenSnackbar(false) }, [1500])
  };

  // console.log("snackbar val", openSnackbar)

  return (
    <>
      <div>
        <div className="outflowsplitbill-semi-container">
          <div className="outflowsplitbill-rule-glcode">
            <select
              onChange={handleChange} required
              name="rule"
              className="outflowsplitbill-select-option"
            >
              <option className="outflowsplitbill-select-option">
                Rule*
              </option>
              <option
                value="Royalties"
                className="outflowsplitbill-select-option"
              >
                Royalties
              </option>
              <option
                value="Cost Recovery"
                className="outflowsplitbill-select-option"
              >
                Cost Recovery
              </option>
            </select>
          </div>
          <div>
            <select
              required
              type="text"
              name="gl_code"
              style={{
                height: 30,
                width: "100%",
                border: "1px solid #cccccc",
                borderRadius: "3px",
              }}
              onChange={(e) => handleChange(e)}
            >
              <option>GL Code*</option>
              {glCodeList?.map((item) => {
                return <option key={item?.id} value={item?.value}>{item?.title}</option>
              })}
            </select>
          </div>
        </div>

        <OutflowSplitbillShare
          splitbill={splitbill}
          setSplitbill={setSplitbill}
          amt={amt} gst={gst}
          currency={currency} />

        <div className="outflowsplitbill-cancel-confirm-button">
          <Button
            sx={{ fontSize: "0.6rem" }}
            size="small"
            variant="outlined"
            onClick={() => handleSplitFlag()}
          >
            Cancel
          </Button>
          <LoadingButton
            sx={{ fontSize: "0.6rem" }}
            size="small"
            variant="contained" loading={isSubmit}
            loadingPosition="end"
            onClick={handleSubmit}
          >
            Confirm Split
          </LoadingButton>
        </div>
      </div>
      <SnackbarMsg apiMsg={apiMsg} open={openSnackbar} color={MsgColor} />
    </>

  );
};

export default OutflowSplitbill;
