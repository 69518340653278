import React, { useEffect } from "react";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import "./BillProducts.css";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

const BillProducts = ({ bill, setBill, isTaxExcl }) => {
  // console.log("isTaxExcl", isTaxExcl)
  const [products, setProducts] = useState([]);

  const addProduct = (e) => {
    e.preventDefault();

    const newProduct = {
      description: null,
      quantity: null,
      price: null,
      // gl_code: "",
      tax_rate: null,
    };

    //setProd(newProduct);

    setProducts([...products, newProduct]);
  };

  const handleChange = (idx, event) => {
    const { name, value } = event.target;
    const updatedProducts = products.map((product, index) =>
      idx === index ? { ...product, [name]: value } : product
    );
    const taxFirst = updatedProducts[0]?.tax_rate;
    setProducts(
      updatedProducts.map((e) => ({
        ...e,
        tax_rate: taxFirst,
      }))
    );
  };

  useEffect(() => {
    setBill({ ...bill, bill_products: products });
  }, [products]);

  return (
    <div style={{ margin: "10px 0" }} className="billProduct">
      <Table component={Paper} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="">Description*</TableCell>
            <TableCell align="">Quantity*</TableCell>
            <TableCell align="">Price*</TableCell>
            <TableCell align="">Tax Rate</TableCell>
            <TableCell align="">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((element, index) => (
            <TableRow key={element.name}>
              <TableCell align="left">
                <TextField
                  id="outlined-basic"
                  size="small"
                  type="text"
                  name="description"
                  inputProps={{
                    maxlength: 2000,
                    style: { fontSize: "12px", height: 12, width: "100%" },
                  }}
                  value={element.description}
                  required
                  onChange={(event) => handleChange(index, event)}
                />
              </TableCell>
              <TableCell align="left">
                <TextField
                  id="outlined-basic"
                  size="small"
                  type="number"
                  required
                  name="quantity"
                  inputProps={{ min: 0, style: { fontSize: "12px", height: 12 } }}
                  value={element.quantity}
                  // onChange={(event) => handleChange(index, event)}
                  onChange={(event) => {
                    if (event.target.value.length <= 10) {
                      handleChange(index, event);
                    }
                  }}
                />
              </TableCell>
              <TableCell align="left">
                <TextField
                  id="outlined-basic"
                  size="small"
                  required
                  type="number"
                  name="price"
                  inputProps={{ min: 0, maxlength: 10, step: 0.01, style: { fontSize: "12px", height: 12 } }}
                  value={element.price}
                  onChange={(event) => {
                    if (event.target.value.length <= 10) {
                      handleChange(index, event);
                    }
                  }}
                />
              </TableCell>
              <TableCell align="left">
                <TextField
                  id="outlined-basic"
                  size="small"
                  required
                  type="number"
                  name="tax_rate" style={{ background: isTaxExcl ? "#f4f4f4" : "" }}
                  disabled={index > 0 || isTaxExcl}
                  inputProps={{ style: { fontSize: "12px", height: 12 } }}
                  value={isTaxExcl === false ? element.tax_rate : ""}
                  // onChange={(event) => handleChange(index, event)}
                  onChange={(event) => handleChange(index, event)}
                />
              </TableCell>
              <TableCell align="left">
                <TextField
                  id="outlined-basic"
                  size="small"
                  required
                  type="number"
                  name="amount"
                  inputProps={{ style: { fontSize: "12px", height: 12, background: '#f4f4f4' } }}
                  value={element.quantity * element.price}
                  onChange={(event) => handleChange(index, event)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div>
        <FontAwesomeIcon
          icon={faPlusCircle}
          onClick={addProduct}
          style={{
            color: "#1976d2",
            height: "30px",
            margin: "15px 0",
            display: "flex",
            justifyContent: "flex-start",
          }}
        />
      </div>
    </div>
  );
};

export default BillProducts;