import React from 'react';
import './BiReports.css';
import refresh from '../../assest/Refresh_Logo_Large (2).jpg'
import zones from '../../assest/Zones Logo-930x310(1) (2).jpg'
import oncore from '../../assest/Oncore Logo.jpeg'

let refreshReport = "https://app.powerbi.com/view?r=eyJrIjoiZGRkMDc0NGMtMzk5OC00ZTAxLWE5YTYtNWJiOTU2MzlkNTZmIiwidCI6IjdmOWI1ZGI0LTlkMTUtNGQ4NS04MTBmLWFhZTdiNDgyYWFkNiJ9&embedImagePlaceholder=true";
let oncoreReport = "https://app.powerbi.com/view?r=eyJrIjoiMDA4YzkzNWItNGFhNy00MDgxLWFjMTUtN2QxNmNjNDQ2NmU1IiwidCI6IjdmOWI1ZGI0LTlkMTUtNGQ4NS04MTBmLWFhZTdiNDgyYWFkNiJ9&embedImagePlaceholder=true";
let zonesReport = "https://app.powerbi.com/view?r=eyJrIjoiOGJkZTIxOTItNjhmOC00OGI4LWExZDgtZGQwNzE2NzI1NmJmIiwidCI6IjdmOWI1ZGI0LTlkMTUtNGQ4NS04MTBmLWFhZTdiNDgyYWFkNiJ9&embedImagePlaceholder=true";

let wsiReport = "";

const imgLogo = { width: "150px" }
const wsiLogo = { width: '50px' }

const Tab = ({ src, onClick, style, disabled }) => {
    return (
        <div className='users-login-tabs'>
            <div className='user-login-tab'>
                <div className=''>
                    <img src={src} style={style} alt='logo' />
                </div>
                <button disabled={disabled} style={{ background: disabled ? "#EEEEEE" : "", color: disabled ? "black" : "" }} onClick={onClick} className='redirect-btn'>View Report</button>
            </div>
        </div >
    )
}


const BiReports = () => {
    const handleClickReports = (id) => {
        if (id === 'refresh') {
            window.open(refreshReport, "_blank");
        }
        if (id === 'oncore') {
            window.open(oncoreReport, "_blank");
        }
        if (id === 'zones') {
            window.open(zonesReport, "_blank");
        }
        if (id === 'wsi') {
            window.open(wsiReport, "_blank");
        }
    };

    return (
        <>
            <div className="bireport_container">
                <div className="bireport_mainDiv">
                    <div className='left-div'>Business Intelligence Report</div>
                    <div className='org-btns'>
                        <Tab disabled={false} onClick={() => handleClickReports("refresh")} companyName={"REFRESH"} src={refresh} style={imgLogo} />
                        <Tab disabled={false} onClick={() => handleClickReports("zones")} companyName={"ZONES"} src={zones} style={imgLogo} />
                        <Tab disabled={false} onClick={() => handleClickReports("oncore")} companyName={"ONCORE"} src={oncore} style={imgLogo} />
                        <Tab disabled={true} onClick={() => handleClickReports("wsi")} companyName={"WE SORT IT"} src={"https://cdn.prod.website-files.com/64053336ec8bf72cbd75a9d1/6568e432d84ced6e55865d40_We%20Sort%20It-Parent-Services.svg"} style={wsiLogo} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default BiReports;
