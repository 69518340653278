import React, { useState } from "react";
import DirectorPayableInvoices from "../DirectorPayableInvoices/DirectorPayableInvoices";
import { Button } from "@mui/material";
import { DirectorReceivableInvoices } from "../DirectorReceivableInvoices/DirectorReceivableInvoices";

const DashboardNew = ({ user }) => {
  const [selectedComponent, setSelectedComponent] = useState("DirectorPayableInvoices");

  const handleComponentChange = (componentName) => {
    setSelectedComponent(componentName);
  };

  const renderSelectedComponent = () => {
    switch (selectedComponent) {
      case "DirectorPayableInvoices":
        return <DirectorPayableInvoices user={user} />;
      case "DirectorReceivableInvoices":
        return <DirectorReceivableInvoices user={user} />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="outflownavbar-container">
        <div className="outflownavbar-container-divitemsize">
          <Button
            style={{
              textDecoration: "none",
              fontSize: "0.6rem",
              color: selectedComponent === "DirectorPayableInvoices" ? "white" : "#146EF5",
            }}
            variant={selectedComponent === "DirectorPayableInvoices" ? "contained" : null}
            onClick={() => handleComponentChange("DirectorPayableInvoices")}
          >
            Payable Invoices
          </Button>
        </div>
        <div className="outflownavbar-container-divitemsize">
          <Button
            style={{
              textDecoration: "none",
              fontSize: "0.6rem",
              color: selectedComponent === "DirectorReceivableInvoices" ? "white" : "#146EF5",
            }}
            variant={selectedComponent === "DirectorReceivableInvoices" ? "contained" : null}
            onClick={() => handleComponentChange("DirectorReceivableInvoices")}
          >
            Receivable Invoices
          </Button>
        </div>
      </div>
      {/* Render selected component */}
      {renderSelectedComponent()}
    </div>
  );
};


export default DashboardNew;
