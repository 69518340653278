import React, { useState } from "react";
import "./Navbar.css";
import { signOut } from "../../services/api.service";
import LogoutIcon from '@mui/icons-material/Logout';
import { Avatar, IconButton } from "@mui/material";

const Navbar = ({ user }) => {
  const [imageError, setImageError] = useState(false);
  const handleLogout = async () => {
    return signOut();
  };

  return (
    <>
      <div className="navbar-main-div">
      </div>
      <div>
        <div className="main-div">
          <div className="left-div">CENTRAL</div>
          <div className="right-div">
            <div className="inner-right-div">
              <div className="top-div">
                <span style={{ fontSize: "0.8rem", fontWeight: "600" }}>{user?.name}</span> <br />
                <span style={{ fontSize: "0.8rem", fontWeight: "400" }}>{user?.email}</span>
              </div>
              {!imageError && user?.picture ? (
                <img
                  src={user.picture}
                  alt="Admin/Director"
                  onError={() => setImageError(true)}
                  style={{ width: "40px", height: "40px", borderRadius: "50%" }}
                />
              ) : (
                <Avatar sx={{ width: 35, height: 35 }}>{user?.name.slice(0,1)}</Avatar>
              )}
            </div>
            <div className="inner-left-div">
              <IconButton
                onClick={handleLogout}
              >
                <LogoutIcon color="success" />
              </IconButton>
            </div>
          </div>
        </div>
        {/* <div style={{ padding: "0 1rem" }}>
        <hr />
      </div> */}
      </div>
    </>
  );
};

export default Navbar;
