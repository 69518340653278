import React, { useEffect, useRef } from "react";
import "./OutflowForm.css";
import { useState } from "react";
import { Addbill, getBill } from "../../services/Currency.service";
import BillProducts from "../BillProducts/BillProducts";
import { convertJSONToFormData, fetchApi, validateTaxRates } from "../../services/api.service";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Button } from "@mui/material";
import Spinner from "../../components/Spinner/Spinner";
import LoadingButton from "@mui/lab/LoadingButton";
import SnackbarMsg from "../../components/superadmin-home/components/Common/Snackbar";
import { glCodeList } from "../../Data/data";

const initvalues = {
  invoice_no: null,
  invoice_url: null,
  vendor: null,
  org: null,
  issue_date: null,
  due_date: null,
  po_no: null,
  po_amount: null,
  reference: null,
  currency: null,
  is_tax_exclusive: null,
  bill_products: [],
};

const OutflowForm = ({ handleClose, setCallBillApi, callBillApi }) => {
  const [bill, setBill] = useState(initvalues);
  const [st, setSt] = useState(0);
  const [tx, setTx] = useState(0);
  const [tot, setTot] = useState(0);
  const [totls, setTotls] = useState({ sub_t: 0, tol_gst: 0, tot_amt: 0 });
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [apiMsg, setApiMsg] = useState(null)
  const [MsgColor, setMsgColor] = useState(null)
  const [isTaxExcl, setIsTaxExcl] = useState(false)

  const {
    invoice_no,
    issue_date,
    due_date,
    po_no,
    po_amount,
    reference,
    is_tax_exclusive,
    bill_products,
    gl_code,
  } = bill;

  const handleChange = (e) => {
    e.preventDefault();
    setIsSubmitable(false);
    if (e.target.name === "vendor") {
      setBill({ ...bill, vendor: autocompleteValue });
    }
    setBill({ ...bill, [e.target.name]: e.target.value });
    // taxrate field validation
    const value = e.target.value === "true" ? true : e.target.value === "false" ? false : null;

    if (value === false) {
      setIsTaxExcl(true);
    } else if (value === true) {
      setIsTaxExcl(false);
    } else {
      console.log("Invalid value selected");
    }
  };

  const calculateTotals = async () => {
    let subT = 0;
    let txr = 0;
    let totamt = 0;
    for (let i = 0; i < bill_products.length; i++) {
      subT +=
        Number(bill_products[i].quantity) * Number(bill_products[i].price);
    }

    if (is_tax_exclusive === "true") {
      txr = (subT * bill_products[0].tax_rate) / 100;
    } else {
      txr = 0;
    }
    totamt = txr + subT;
    const newTotal = { ...totls, sub_t: subT, tol_gst: txr, tot_amt: totamt };
    setTotls(newTotal);
    setIsSubmitable(true);
    return newTotal;
  };

  const calculateGST = () => {
    let txr = 0;
    txr = st * 0.15;
    setTx(txr);

    calculateTotAmount();
  };

  const calculateTotAmount = () => {
    let tls = Number(st) + Number(tx);

    setTot(tls);
  };

  const handleSubmit = async () => {
    setIsSubmit(true)
    let taxRateArr = bill.bill_products.map((item) => item["tax_rate"]);

    // Iterate over each tax_rate value to perform validation
    if (taxRateArr.length || taxRateArr[0] !== null) {
      let validationResult = validateTaxRates(taxRateArr)
      if (!validationResult.isValid) {
        setApiMsg(validationResult.message);
        setMsgColor("red")
        setOpenSnackbar(true);
        setTimeout(() => setOpenSnackbar(false), 1500);
        setIsSubmit(false)
        return;
      }
    }

    const formattedData = {
      ...bill,
    };
    // Convert to submit as a FormData object.
    // Required to send the file to the server.
    const formData = convertJSONToFormData(formattedData);
    if (selectedFile) formData.append("attachment", selectedFile);

    await Addbill(formData).then((result) => {
      setOpenSnackbar(true)
      setApiMsg(result?.message || result?.err)
      setMsgColor(result?.success ? 'green' : 'red')
      setIsLoading(result?.success ? true : false);
      setIsSubmit(result?.success ? true : false)
      setCallBillApi(!callBillApi)
      setTimeout(() => { setOpenSnackbar(false) }, [1500]);
      if (result?.success)
        setTimeout(() => { handleClose() }, 1000)
    })
      .catch((err) => {
        console.log("err", err)
      })
  };


  const [vendors, setVendors] = useState([]);
  const getVendor = async () => {
    const url = await fetchApi("/api/vendors");
    const vendorlist = await url.json();
    setVendors(vendorlist.palyload);
  };

  const [orgs, setOrgs] = useState([]);
  const [isSumitable, setIsSubmitable] = useState(false);
  const getOrg = async () => {
    const urlOrg = await fetchApi("/api/orgs");
    const orglist = await urlOrg.json();
    setOrgs(orglist.palyload);
  };

  const handleEnterBill = (e) => {
    e.preventDefault();
    if (bill_products?.length === 0) {
      setApiMsg("Please add Price and Qty")
      setMsgColor("red")
      setOpenSnackbar(true)
      setTimeout(() => { setOpenSnackbar(false) }, 1500);
    }
    if (isSumitable && bill?.bill_products?.length) {
      handleSubmit();
    } else if (!isSumitable && bill?.bill_products?.length) {
      calculateTotals();
    }
  }


  function setBillAndResetIsSubmitable(data) {
    setBill(data);
    setIsSubmitable(false);
  }
  useEffect(() => {
    getVendor();
  }, []);
  useEffect(() => {
    getOrg();
  }, []);

  //Autocomplete
  //......
  //.....
  const [jsonresult, setJsonresult] = useState([]);
  const test = async () => {
    const data = await fetchApi(`/api/vendors/search?name=exter`);
    const response = await data.json();
    setJsonresult(response.payload);
  };

  useEffect(() => {
    // test();

    // Example: Set autocomplete with existing value (e.g. for initial load).
    const exampleInitialAutocompleteValue = "";
    setAutoCompleteValue(exampleInitialAutocompleteValue);
    handleDebounceSearch(exampleInitialAutocompleteValue);
  }, [isSubmit]);

  //.......
  //.......

  const ref = React.useRef();
  const handleCancel = () => {
    handleClose();
    ref.current.reset();
    setBill(initvalues);
  };

  // START Test search autocomplete things.

  const debounceTimeout = useRef();
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  const [autocompleteValue, setAutoCompleteValue] = useState(null);

  const requestAutocompleteList = async (search) => {
    // No search - return an empty list.
    if (!search?.trim()) return [];

    setAutocompleteLoading(true);
    try {
      const response = await fetchApi(`/api/vendors/search?name=${search}`);
      const data = await response.json();
      return data.payload;
    } catch (error) {
      console.error(error);
      return [];
    } finally {
      setAutocompleteLoading(false);
    }
  };

  const handleDebounceSearch = (search) => {
    const DEBOUNCE_MS = 500;

    // Clear the previous timeout.
    clearTimeout(debounceTimeout.current);

    // Debouncing.
    debounceTimeout.current = setTimeout(async () => {
      // Do request.
      const returned = await requestAutocompleteList(search);
      setJsonresult(returned);
    }, DEBOUNCE_MS);
  };

  const onAutocompleteChange = (_e, v) => {
    // console.log(v)
    // setAutoCompleteValue(v)
    // setAutoCompleteValue(v ? v.id : null)
    const vendor = v ? v.name : null;
    setAutoCompleteValue(vendor);
    setBill({ ...bill, vendor });

    // Clear search if the input was cleared.
    if (!v) handleDebounceSearch(null);
  };

  // END Test search autocomplete things.

  return (
    <>
      {isLoading ? (
        <div style={{ width: "900px" }}>
          <Spinner />
        </div>
      ) : (
        <div className="outflowform-container">
          <form
            ref={ref}
            id="form-id"
            className="semi-container"
            onSubmit={(e) => handleEnterBill(e)}
          >
            <div className="form-div">
              <div className="form-div-field">
                <Autocomplete
                  options={jsonresult}
                  limitTags={2}
                  name="vendor"
                  onChange={onAutocompleteChange}
                  loading={autocompleteLoading}
                  value={autocompleteValue}
                  size="small"
                  sx={{ width: "100%" }}
                  isOptionEqualToValue={(option, value) =>
                    option?.name === value
                  }
                  getOptionLabel={(option) => option?.name || option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onChange={(e) => handleDebounceSearch(e.target.value)}
                      label="From" required
                      size="small"
                    />
                  )}
                />
                <TextField
                  required
                  type="text"
                  onFocus={(e) => {
                    e.target.type = "date";
                  }}
                  onBlur={(e) => {
                    e.target.type = "text";
                  }}
                  className="box2-options"
                  name="issue_date"
                  inputProps={{ style: { fontSize: "12px", height: 12 } }}
                  id="issue-date outlined-basic"
                  size="small"
                  value={issue_date}
                  sx={{ width: "100%" }}
                  placeholder="Issue date*"
                  onChange={handleChange}
                />
                <TextField
                  id="outlined-basic"
                  type="text"
                  inputProps={{ maxLength: 1000, style: { fontSize: "12px", height: 12 } }}
                  sx={{
                    ".MuiFormLabel-root[data-shrink=false]": { top: "1rem" },
                  }}
                  className="box3-options"
                  name="reference"
                  size="small"
                  value={reference}
                  placeholder="Reference"
                  onChange={handleChange}
                />
              </div>
              <div className="form-div-field">
                <select
                  required
                  name="org"
                  style={{
                    width: "100%",
                    height: 30,
                    border: "1px solid #cccccc",
                  }}
                  className="box1-options"
                  id="outlined-select-currency"
                  onChange={handleChange}
                  value={bill["org"]}
                >
                  <option value="">To*</option>
                  {orgs.map((elements, index) => {
                    return (
                      <option value={elements.name} type="text" key={index}>
                        {elements.name}
                      </option>
                    );
                  })}
                </select>

                <TextField
                  required
                  type="text"
                  onFocus={(e) => {
                    e.target.type = "date";
                    if (issue_date) {
                      e.target.min = issue_date;
                    }
                  }}
                  onBlur={(e) => {
                    e.target.type = "text";
                  }}
                  inputProps={{ style: { fontSize: "12px", height: 12 } }}
                  className="box2-options"
                  name="due_date"
                  id="due_date outlined-basic"
                  size="small"
                  value={due_date}
                  sx={{ width: "100%" }}
                  placeholder="Due date*"
                  onChange={handleChange}
                />
                <select
                  name="currency"
                  select
                  size="small"
                  value={bill.currency}
                  onChange={handleChange}
                  label="Currency"
                  id="outlined-basic"
                  style={{ height: 30, border: "1px solid #cccccc" }}
                  className="box3-options"
                  required
                >
                  <option value="">Currency*</option>
                  <option value="NZD">NZD</option>
                  <option value="AUD">AUD</option>
                  <option value="GBP">GBP</option>
                  <option value="USD">USD</option>
                </select>
              </div>
              <div className="form-div-field">
                <TextField
                  required
                  type="text"
                  sx={{ width: 200 }}
                  className="box1-options"
                  name="invoice_no"
                  size="small"
                  value={invoice_no}
                  id="outlined-basic"
                  inputProps={{ maxLength: 500, style: { fontSize: "12px", height: 12 } }}
                  placeholder="Invoice Number*"
                  onChange={handleChange}
                  variant="outlined"
                />
                <TextField
                  type="number"
                  sx={{ width: 200 }}
                  className="box2-options"
                  name="po_no"
                  id="outlined-basic"
                  inputProps={{ min: 0, maxLength: 100, style: { fontSize: "12px", height: 12 } }}
                  value={po_no}
                  size="small"
                  placeholder="PO Number"
                  onChange={handleChange}
                />
                <select
                  required
                  select
                  size="small"
                  style={{ height: 30, border: "1px solid #cccccc" }}
                  className="box3-options"
                  name="is_tax_exclusive"
                  value={bill.is_tax_exclusive}
                  onChange={handleChange}
                >
                  <option value="">Tax type*</option>
                  <option value={false}>Tax-Exclusive</option>
                  <option value={true}>Tax-Inclusive</option>
                </select>
              </div>
              <div className="form-div-field">
                <TextField
                  type="file"
                  sx={{ width: 200 }}
                  id="outlined-basic"
                  className="box1-options"
                  size="small"
                  onChange={(e) => setSelectedFile(e.target.files[0])}
                  InputProps={{ style: { fontSize: "12px", height: 30 } }}
                  variant="outlined"
                />
                <TextField
                  type="number"
                  sx={{ width: 200 }}
                  className="box2-options"
                  name="po_amount"
                  id="outlined-basic"
                  value={po_amount}
                  size="small" min="0"
                  inputProps={{ min: 0, maxLength: 100, style: { fontSize: "12px", height: 12 } }}
                  placeholder="PO Amount"
                  onChange={handleChange}
                />
              </div>
            </div>

            <div style={{ width: "22%", padding: "10px 0px" }}>
              <select
                required
                type="text"
                name="gl_code"
                style={{
                  height: 30,
                  width: "100%",
                  border: "1px solid #cccccc",
                  borderRadius: "3px",
                }}
                value={gl_code}
                onChange={handleChange}
              >
                <option value="">GL Code*</option>
                {glCodeList?.map((item) => {
                  return <option key={item?.id} value={item?.value}>{item?.title}</option>
                })}
              </select>
            </div>

            <BillProducts bill={bill} setBill={setBillAndResetIsSubmitable} isTaxExcl={isTaxExcl} setIsTaxExcl={setIsTaxExcl} />

            <div className="outflowbill-totals">
              <div className="outflow-totals-box">
                <label> Sub-Total : </label>
                <input
                  disabled
                  type="text"
                  name="sub_total"
                  value={totls.sub_t}
                  style={{ background: isTaxExcl ? "#f4f4f4" : "" }}
                />
              </div>
              <br />
              <div className="outflow-totals-box">
                <label> Total (GST / Tax) : </label>
                <input
                  disabled
                  type="text"
                  name="total_gst"
                  value={totls.tol_gst}
                  style={{ background: isTaxExcl ? "#f4f4f4" : "" }}
                />
              </div>
              <br />
              <div className="outflow-totals-box">
                <label>Total Amt :</label>
                <input
                  disabled
                  type="text"
                  name="total_amt"
                  value={totls.tot_amt}
                  onChange={handleChange}
                  style={{ background: isTaxExcl ? "#f4f4f4" : "" }}
                />
              </div>
            </div>

            <div className="outflowform-button-submit">
              <Button
                sx={{ fontSize: "0.6rem" }}
                size="small"
                variant="outlined"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              {!isSumitable && (
                <Button
                  size="small"
                  sx={{ fontSize: "0.6rem" }}
                  type="submit"
                  variant="contained"
                >
                  Save
                </Button>
              )}
              {isSumitable && (
                <LoadingButton
                  size="small"
                  sx={{ fontSize: "0.6rem" }}
                  variant="contained"
                  type="submit"
                  loading={isSubmit}
                  loadingPosition="end"
                >
                  Submit
                </LoadingButton>
              )}
            </div>
          </form>
        </div>
      )}
      <SnackbarMsg apiMsg={apiMsg} open={openSnackbar} color={MsgColor || "green"} />
    </>
  );
};

export default OutflowForm;
