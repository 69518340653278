import React, { useEffect, useState } from "react";
import "./SendtoxeroPayInv.css";
import { getSplitbillData } from "../../services/Currency.service";
import { fetchApi } from "../../services/api.service";
import { Button, Select, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress } from "@mui/material";
import SnackbarMsg from "../../components/superadmin-home/components/Common/Snackbar";

const SendtoxeroPayInv = () => {
  const [splitbill, setSplitbill] = useState([]);
  const [orgName, setOrgName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [apiMsg, setApiMsg] = useState(null)
  const [MsgColor, setMsgColor] = useState(null)

  const fetchsplitBill = async () => {
    try {
      setIsLoading(true);
      const bills = await getSplitbillData();
      setSplitbill(bills);
    } catch (error) {
      console.error("Error fetching split bills:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchsplitBill();
  }, []);

  const handleOrgNameChange = (event) => setOrgName(event.target.value);

  const handleCreateInvoice = async () => {
    setIsLoading(true);

    const orgInvoiceArray = splitbill.flatMap((elem) =>
      elem.org_splits
        .filter(
          (e) =>
            orgName === e.organisation.name && !e.payable_status && elem.bill.status !== "R" && elem.bill.status === "AP"
        )
        .map((e) => ({
          p_tid: e.organisation.xero_tenant_id,
          p_from: e.organisation.id,
          p_to: elem.bill.organisation.id,
          p_description: e.description,
          p_org_amt: e.payOrganizationAmount,
          p_inv_no: `${e.split_invoice_no}P`,
          p_due_date: elem.bill.due_date,
          p_issue_date: elem.bill.issue_date,
          p_reference: elem.bill.reference,
          split_inv_id: elem.id,
          sbo_id: e.id,
          bill_id: elem.bill.id,
          p_gl_code: elem.gl_code,
        }))
    );

    const payInv = { p_invs: orgInvoiceArray };

    try {
      const response = await fetchApi(`/api/xero/pay-invoices/send-to-xero`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payInv),
      });

      const result = await response.json();

      if (result.success) {
        // alert("Payable invoices sent to Xero successfully.");
        setMsgColor("green")
        setApiMsg("Payable invoices sent to Xero successfully")
        setOpenSnackbar(true)
        fetchsplitBill();
      } else if (result.ind === "UA") {
        // alert("Connect to Xero and try again.");
        setMsgColor("red")
        setApiMsg("Connect to Xero and try again")
        setOpenSnackbar(true)
      } else {
        // alert("Failed to send invoices. Please try again.");
        setMsgColor("red")
        setApiMsg("Failed to send invoices. Please try again.")
        setOpenSnackbar(true)
      }
    } catch (error) {
      console.error("Error sending invoices to Xero:", error);
      alert("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="send-to-xero-container">
      {isLoading ? (
        <div className="loading-container">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="filter-by-org">
            <Select
              value={orgName}
              onChange={handleOrgNameChange}
              displayEmpty
              variant="outlined"
              fullWidth
              size="small"
              className="org-select-dropdown"
            >
              <MenuItem value="">
                Filter By Organisation
              </MenuItem>
              {[
                "Traffic NZ",
                "Refresh NZ",
                "Refresh US",
                "Refresh UK",
                "Refresh AU",
                "Refresh International",
                "Zones NZ",
                "Zones AU",
                "Zones International",
                "Oncore NZ",
                "Oncore International",
              ].map((org) => (
                <MenuItem key={org} value={org}>
                  {org}
                </MenuItem>
              ))}
            </Select>
          </div>
          <TableContainer
            component={Paper}
            className="table-container"
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Invoice No</TableCell>
                  <TableCell>From</TableCell>
                  <TableCell>To</TableCell>
                  <TableCell>Currency</TableCell>
                  <TableCell>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {splitbill.flatMap((elem) =>
                  elem.bill.status === "AP"
                    ? elem.org_splits.map((el) =>
                      !el.payable_status &&
                        (orgName === el.organisation.name || orgName === "") ? (
                        <TableRow key={el.id}>
                          <TableCell>{el.split_invoice_no}P</TableCell>
                          <TableCell>{el.organisation.name}</TableCell>
                          <TableCell>{elem.bill.organisation.name}</TableCell>
                          <TableCell>{el.organisation.currency}</TableCell>
                          <TableCell>{el.payOrganizationAmount}</TableCell>
                        </TableRow>
                      ) : null
                    )
                    : []
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {orgName && (
            <div className="send-to-xero-button">
              <Button
                size="small"
                onClick={handleCreateInvoice}
                variant="contained"
                color="primary"
              >
                Send to Xero
              </Button>
            </div>
          )}
        </>
      )}
      <SnackbarMsg apiMsg={apiMsg} open={openSnackbar} color={MsgColor} />
    </div>
  );
};

export default SendtoxeroPayInv;
