import React, { useEffect, useState } from "react";
import {
  GetUsers,
  getOrgInvoiceUrl,
} from "../../services/Currency.service";
import { fetchApi } from "../../services/api.service";
import Spinner from "../../components/Spinner/Spinner";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

const DirectorPayableInvoices = ({ user }) => {
  const [payableInvoices, setPayableInvoices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchPayableInvoices = async () => {
    try {
      setIsLoading(true);
      const users = await GetUsers();
      const currentUser = users?.find((elem) => elem?.id === user?.id);
      const userOrgs = currentUser?.organisations || [];

      const allInvoices = await Promise.all(
        userOrgs.map(async (org) => {
          const response = await fetchApi(`/api/xero/ginvoices?org_from=${org.name}&type=P`);
          const data = await response.json();
          return data?.payload || [];
        })
      );

      setPayableInvoices(allInvoices.flat());
    } catch (error) {
      console.error("Error fetching invoices:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInvoiceClick = async (invoiceNo) => {
    try {
      const url = await getOrgInvoiceUrl(invoiceNo);
      if (url) window.open(url, "_blank");
    } catch (error) {
      console.error("Error fetching invoice URL:", error);
    }
  };

  useEffect(() => {
    fetchPayableInvoices();
  }, []);

  return (
    <div>
      {isLoading ? (
        <Spinner />
      ) : (
        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
          <Typography variant="h6" align="center" gutterBottom>
            Payable Invoices
          </Typography>
          <Table size="small" sx={{ minWidth: 650, "& th, & td": { textAlign: "center" } }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                <TableCell>Invoice</TableCell>
                <TableCell>Invoice No.</TableCell>
                <TableCell>From</TableCell>
                <TableCell>To</TableCell>
                <TableCell>GL Code</TableCell>
                <TableCell>Invoice Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {payableInvoices.length > 0 ? (
                payableInvoices.map((invoice, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <img
                        src="https://cdn.iconscout.com/icon/free/png-256/free-pdf-file-20-504245.png"
                        alt="PDF"
                        style={{ cursor: "pointer", width: 24 }}
                        onClick={() => handleInvoiceClick(invoice.invoice_no)}
                      />
                    </TableCell>
                    <TableCell>{invoice.invoice_no}</TableCell>
                    <TableCell>{invoice.org_from}</TableCell>
                    <TableCell>{invoice.org_to}</TableCell>
                    <TableCell>{invoice.gl_code}</TableCell>
                    <TableCell>{invoice.amount}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Typography variant="body1" align="center">
                      No invoices available.
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default DirectorPayableInvoices;
